<template>
  <div class="dashboard">
    <h1></h1>
    <div class="card">
      <div class="card-content">
        <div class="media">
          <div class="media-left">
              <Avatar
                :fullName="CURRENT_USER.fullName"
                :size="5"
              />
          </div>
          <div class="media-content" style="overflow: unset">
            <p class="title is-4">{{CURRENT_USER.fullName}}</p>
            <p class="subtitle is-6">{{CURRENT_USER.email}}</p>
          </div>
        </div>
        <div class="content">
          <div>
            Organization Name: <b-tag type="is-primary" size="is-medium">
              <b>{{ ACTIVE_WORKSPACE.title }}</b>
            </b-tag>
          </div>
          <div>
            Status: <b-tag type="is-warning">
              <b>{{ isOwnerOfActiveWorkspace ? 'Owner' : 'Member' }}</b>
            </b-tag>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
        <b-icon
          icon="rocket"
          size="is-small">
        </b-icon>&nbsp;
          Total Number of Projects
        </p>
      </header>
      <div class="card-content">
        <div class="content">
          <div class="is-size-2 has-text-centered">
            {{ ACTIVE_WORKSPACE_PROJECTS.length }}
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="columns">
      <div class="column">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              <b-icon
                icon="cube"
                size="is-small">
              </b-icon>&nbsp;
              <span class="is-italic has-text-grey">
                # Stories</span>&nbsp; {{ boardStatusesIDsWithName[0].title }}
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <div class="is-size-2 has-text-centered">
                {{ (sortedUserStoriesGroupedByStatus[boardStatusesIDsWithName[0]._id]
                  && sortedUserStoriesGroupedByStatus[boardStatusesIDsWithName[0]._id].length)
                  || 0 }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              <b-icon
                icon="cube"
                size="is-small">
              </b-icon>&nbsp;
              <span class="is-italic has-text-grey">
                # Stories</span>&nbsp; {{ boardStatusesIDsWithName[1].title }}
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <div class="is-size-2 has-text-centered">
                {{ (sortedUserStoriesGroupedByStatus[boardStatusesIDsWithName[1]._id]
                  && sortedUserStoriesGroupedByStatus[boardStatusesIDsWithName[1]._id].length)
                  || 0 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              <b-icon
                icon="cube"
                size="is-small">
              </b-icon>&nbsp;
              <span class="is-italic has-text-grey">
                # Stories</span>&nbsp; {{ boardStatusesIDsWithName[2].title }}
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <div class="is-size-2 has-text-centered">
                {{ (sortedUserStoriesGroupedByStatus[boardStatusesIDsWithName[2]._id]
                  && sortedUserStoriesGroupedByStatus[boardStatusesIDsWithName[2]._id].length)
                  || 0 }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              <b-icon
                icon="cube"
                size="is-small">
              </b-icon>&nbsp;
              <span class="is-italic has-text-grey">
                # Stories</span>&nbsp; {{ boardStatusesIDsWithName[3].title }}
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <div class="is-size-2 has-text-centered">
                {{ (sortedUserStoriesGroupedByStatus[boardStatusesIDsWithName[3]._id]
                  && sortedUserStoriesGroupedByStatus[boardStatusesIDsWithName[3]._id].length)
                  || 0 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  map, forEach, groupBy, sortBy,
} from 'lodash';
import { mapGetters } from 'vuex';
import { CONSTANTS as WORKSPACE_CONSTANTS } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as PROJECT_CONSTANTS, } from '@/store/modules/project/project.constants';
import { CONSTANTS as STORY_CONSTANTS } from '@/store/modules/story/story.constants';
import { CONSTANTS as BOARD_CONSTANTS } from '@/store/modules/board/board.constants';
import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';
import Avatar from '@/components/pureComponents/Avatar.component.vue';

const clone = require('rfdc')({ proto: false });

export default {
  name: 'DashboardView',
  computed: {
    ...mapGetters([
      USER_CONSTANTS.GETTERS.CURRENT_USER,
      WORKSPACE_CONSTANTS.GETTERS.ACTIVE_WORKSPACE,
      PROJECT_CONSTANTS.GETTERS.ACTIVE_WORKSPACE_PROJECTS,
      STORY_CONSTANTS.GETTERS.USER_STORIES,
      BOARD_CONSTANTS.GETTERS.BOARD_STATUSES,
    ]),
    isOwnerOfActiveWorkspace() {
      return this.CURRENT_USER._id === this.ACTIVE_WORKSPACE.owner;
    },
    transformedUserStories() {
      let copy = clone(this.USER_STORIES);

      copy = map(copy, (story) => {
        let boardStatusObject;

        forEach(this.BOARD_STATUSES, (boardStatus) => {
          if (boardStatus._id === story.status) boardStatusObject = boardStatus;
        });

        forEach(boardStatusObject.stories, (boardItem) => {
          if (boardItem.story === story._id) {
            // eslint-disable-next-line no-param-reassign
            story.position = boardItem.position;
          }
        });

        return story;
      });

      copy = map(copy, (story) => {
        this.ACTIVE_WORKSPACE_PROJECTS.forEach((project) => {
          if (story.project === project._id) {
            // eslint-disable-next-line no-param-reassign
            story.color = project.color;
          }
        });

        return story;
      });

      return copy;
    },
    userStoriesGroupedByStatus() {
      return groupBy(this.transformedUserStories, 'status');
    },
    sortedUserStoriesGroupedByStatus() {
      const copy = clone(this.userStoriesGroupedByStatus);

      forEach(this.userStoriesGroupedByStatus, (status, key) => {
        copy[key] = sortBy(status, 'position');
      });

      return copy;
    },
    boardStatusesIDs() {
      return map(this.BOARD_STATUSES, status => status._id);
    },
    boardStatusesIDsWithName() {
      return map(this.BOARD_STATUSES, status => ({
        _id: status._id,
        title: status.title
      }));
    },
  },
  components: { Avatar },
};
</script>
