<template>
  <div
    class="avatar-circle"
    :class="classObject"
    >
    <span class="initials">
      {{ getInitials(fullName) }}
    </span>
  </div>
</template>

<script>
import { getInitials } from '../../utils/utils';

export default {
  name: 'Avatar',
  data() {
    return {
      classObject: {
        // will default avatar size to 2 if no 'size' is passed
        [`avatar-circle-size-${this.size || 2}`]: this.size,
      }
    };
  },
  props: {
    fullName: {
      type: String,
      required: true,
      validator: value => !!value
    },
    size: {
      type: Number
    }
  },
  methods: {
    getInitials
  }
};
</script>

<style lang="scss">
  .avatar-circle {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background: #009CE0;
    overflow: hidden;
    display: inline-block;

    &-size {
      &-1 {
        width: 22px;
        height: 22px;
      }
      &-2 {
        width: 26px;
        height: 26px;
      font-size: 1.1em;
      }
      &-3 {
        width: 32px;
        height: 32px;
      font-size: 1.3em;
      }
    }
  }
  .initials {
    width: 100%;
    height: 100%;
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: "Courier New", monospace;
    font-weight: bold;
  }
</style>
